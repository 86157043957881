import React, { Fragment } from 'react';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import { withNamespaces } from 'react-i18next';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import { Hidden, useMediaQuery } from '@material-ui/core';
import { get } from 'lodash';
import {
  COMPONENT_GRID_CONTAINER,
  COMPONENT_GRID_BLOCK,
  COMPONENT_NAME,
} from './locators';
import Grid from '../../../components/molecules/Grid';
import Typography from '../../../components/atoms/Typography';
import CustomLink from '../../../components/atoms/Link';
import {
  getComponentName,
  getPageName,
} from '../../../utils/getPageComponentInfo';
import { pageContentType } from '../../../utils/getContentType';
import { createDynamicLabels } from '../../../../../src/utils/analytics';
import useStyles from './style';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { common, palette } from '../../../../styles/themes/vad/palette';
import { ATTRIBUTE_TYPE, EVENT_LISTING_TEMPLATE_ID, EVENT_TYPE, AD_EVENT_TEMPLATE_ID } from '../../../../constants';
import Box from '../../../components/molecules/Box';
import Picture from '../../../components/atoms/Picture';
import { getUId } from '../../../../utils/utility';

const Breadcrumbs = props => {
  const isMobile = useMediaQuery(`(max-width:${1023}px)`);
  const { customContentList: BreadcrumbList } = props.fields;
  const params = props.params;
  const includePageHeading = params ? params.includePageHeading : false;
  const includeEventHeading = params ? params.eventHeadingIncluded : false;
  const supergraphicImage = includePageHeading ? params.supergraphicImage : null;
  const bgImage = supergraphicImage ? supergraphicImage.slice(supergraphicImage.indexOf('{') + 1, supergraphicImage.indexOf('}')).replace(/-/g, '') + '.ashx' : ''
  const sitecoreContext = props.sitecoreContext;
  const classes = useStyles({ ...params, bgImage });
  const contentType = pageContentType({ sitecoreContext }).toLowerCase();
  const eventListingTemplateId = get(sitecoreContext, 'route.templateId', '');
  const eventAdCalenderLogo = get(sitecoreContext, 'route.fields.eventAdCalenderLogo.value.src', '');
  const eventAdCalenderLogoMobile = get(sitecoreContext, 'route.fields.eventAdCalenderLogoMobile.value.src', '');
  let title =
    get(sitecoreContext, 'route.fields.pageHeading.value', '') ? get(sitecoreContext, 'route.fields.pageHeading', '') : get(sitecoreContext, 'route.fields.name', '');
  if (contentType === ATTRIBUTE_TYPE) {
    title = { value: get(sitecoreContext, 'tag', '') };
  }
  const uid = getUId(props)
  const createSVG = (type = '', fill = '', customClass = '') => {
    switch (type) {
      case 'arrow':
        return (
          <svg className={customClass} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.66699 12.5L10.667 8.5L6.66699 4.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        )
        break;
        return <></>
    }
  }
  const hideMainCrumb = get(props, 'params.style', '') === 'Breadcrumb on Banner'
  const isADDetailPage = includeEventHeading;
  // const SEPERATOR = isADDetailPage ? createSVG('arrow') : '/';
  const BreadcrumbListLength = BreadcrumbList ? BreadcrumbList.length : 0;
  const showArrow = () => {
    return '/'
  }
  return (
    <div className={`${hideMainCrumb ? "hide" : ""} breadcrumbWrapper`}>
      <Grid container={includePageHeading} className={`${includePageHeading ? classes.pageHeadingWrapper : classes.root} ${isADDetailPage && isMobile ? 'ADDetailPageMargin' : ''}`} data-locator={COMPONENT_GRID_BLOCK} uid={uid}>
        <Grid container spacing={0} data-locator={COMPONENT_GRID_CONTAINER}>
          <Grid item xs={12}>
            <nav aria-label="breadcrumb" className={`${classes.breadcrumbs} ${isADDetailPage ? 'textStyle' : ''} ${isADDetailPage && isMobile ? 'AdDetailNoWrap' : ''}`}
              data-locator={COMPONENT_NAME}>
              <ol itemScope="itemScope" itemType="https://schema.org/BreadcrumbList" aria-label="breadcrumb">
                {BreadcrumbList && BreadcrumbList.length > 0 &&
                  BreadcrumbList.map((item, index) => {
                    const { displayName, link } = item;
                    const lastIndex = BreadcrumbList.length - 1;
                    const templateName = get(item, 'templateName', '')

                    if (templateName === "404Page") {
                      return;
                    }

                    // // When isMobile is true, render only the first and last breadcrumb with "..." in between
                    // if (isMobile) {
                    //   if (index === 0) {
                    //     return (
                    //       <Fragment key={index}>
                    //         <li
                    //           itemProp="itemListElement"
                    //           itemScope="itemScope"
                    //           itemType="https://schema.org/ListItem"
                    //         >
                    //           <CustomLink
                    //             id={index}
                    //             customHref={link}
                    //             isCustom={true}
                    //             isBreadcrumb={true}
                    //             hoverColor={palette.text.primary}
                    //             linkColor={palette.text.secondary}
                    //             aria-label={displayName}
                    //             data-id={get(
                    //               analyticsData,
                    //               'dataIds.pageBodyNavLinkClick',
                    //               ''
                    //             )}
                    //             data-link={createDynamicLabels([
                    //               getPageName(props),
                    //               getComponentName(props),
                    //               `${get(
                    //                 analyticsData,
                    //                 'dataLinksText.breadCrumbLink',
                    //                 ''
                    //               )}-${displayName}`,
                    //             ])}
                    //           >
                    //             {displayName && (
                    //               <>
                    //                 <Typography variant="body2" component="span" itemProp="name">
                    //                   {displayName}
                    //                 </Typography>
                    //               </>
                    //             )}
                    //           </CustomLink>
                    //           <meta itemProp="position" content={index + 1} />
                    //         </li>
                    //         <li className="MuiBreadcrumbs-separator">
                    //           <Typography variant="body2" component="span">
                    //             {showArrow()}
                    //           </Typography>
                    //         </li>
                    //       </Fragment>
                    //     );
                    //   }

                    //   if (index === 1 && BreadcrumbList.length > 2) {
                    //     return (
                    //       <Fragment key={index}>
                    //         <li>
                    //           <Typography variant="body2" component="span">
                    //             ...
                    //           </Typography>
                    //         </li>
                    //         <li className="MuiBreadcrumbs-separator">
                    //           <Typography variant="body2" component="span">
                    //             {showArrow()}
                    //           </Typography>
                    //         </li>
                    //       </Fragment>
                    //     );
                    //   }

                    //   if (index === lastIndex) {
                    //     return (
                    //       <Fragment key={index}>
                    //         <li
                    //           itemProp="itemListElement"
                    //           itemScope="itemScope"
                    //           itemType="https://schema.org/ListItem"
                    //         >
                    //           <Typography
                    //             variant="body2"
                    //             component="span"
                    //             aria-current={displayName}
                    //             className={classes.lastBreadcrumb}
                    //             itemProp="name"
                    //           >
                    //             {displayName}
                    //           </Typography>
                    //           <meta itemProp="position" content={index + 1} />
                    //         </li>
                    //       </Fragment>
                    //     );
                    //   }

                    //   return null;
                    // }

                    // Default rendering when isMobile is false
                    if (index < BreadcrumbListLength - 1) {
                      return (
                        <Fragment key={index}>
                          <li
                            itemProp="itemListElement"
                            itemScope="itemScope"
                            itemType="https://schema.org/ListItem"
                          >
                            <CustomLink
                              className={isADDetailPage ? 'AdDetailPageCrumb' : ''}
                              key={index}
                              id={index}
                              customHref={link}
                              isCustom={true}
                              isBreadcrumb={true}
                              hoverColor={isADDetailPage ? common.SeaGreen : palette.text.primary}
                              linkColor={isADDetailPage ? common.SeaGreen : palette.text.secondary}
                              aria-label={displayName}
                              data-id={get(
                                analyticsData,
                                'dataIds.pageBodyNavLinkClick',
                                ''
                              )}
                              data-link={createDynamicLabels([
                                getPageName(props),
                                getComponentName(props),
                                `${get(
                                  analyticsData,
                                  'dataLinksText.breadCrumbLink',
                                  ''
                                )}-${displayName}`,
                              ])}
                            >
                              {displayName && (
                                <>
                                  <Typography variant="body2" component="span" itemProp="name">
                                    {displayName}
                                  </Typography>
                                </>
                              )}
                            </CustomLink>
                            <meta itemProp="position" content={index + 1} />
                          </li>
                          <li className="MuiBreadcrumbs-separator">
                            <Typography variant="body2" component="span">
                              {showArrow()}
                            </Typography>
                          </li>
                        </Fragment>
                      );
                    } else {
                      return (
                        displayName && (
                          <Fragment key={index}>
                            <li
                              itemProp="itemListElement"
                              itemScope="itemScope"
                              itemType="https://schema.org/ListItem"
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                aria-current={displayName}
                                className={`${classes.lastBreadcrumb} ${isADDetailPage ? 'detailPageLastBreadCrumb' : ''}`}
                                itemProp="name"
                              >
                                {displayName}
                              </Typography>
                              <meta itemProp="position" content={index + 1} />
                            </li>
                          </Fragment>
                        )
                      );
                    }
                  })}
              </ol>
            </nav>
          </Grid>
        </Grid>

        {includePageHeading &&
          <Grid container spacing={0}>
            <Grid item xs={12} className={contentType === EVENT_TYPE && eventListingTemplateId === EVENT_LISTING_TEMPLATE_ID && classes.eventListingLogoWrapper}>
              <Typography wordBreak title={title.value} className={classes.headingTitle} variant="h2" component="h1" gutterBottom={false}>
                {title && <Text field={title} />}
              </Typography>
              {eventListingTemplateId === EVENT_LISTING_TEMPLATE_ID && contentType === EVENT_TYPE &&
                <>
                  <Hidden mdDown implementation="css">
                    <Box pb={{ xs: 5 }} className={classes.imageWrapper}>
                      <Picture
                        media={{
                          image: {
                            value: {
                              src: eventAdCalenderLogo,
                            },
                          },
                          renditionNeeded: false,
                          fallbackBackgroundNeeded: false,
                        }}
                        isParalax={false}
                        alternateAltText={title && title.value}
                      />
                    </Box>
                  </Hidden>
                  <Hidden lgUp implementation="css">
                    <Box pb={{ xs: 5 }} className={classes.imageWrapper}>
                      <Picture
                        media={{
                          image: {
                            value: {
                              src: eventAdCalenderLogoMobile,
                            },
                          },
                          renditionNeeded: false,
                          fallbackBackgroundNeeded: false,
                        }}
                        alternateAltText={title && title.value}
                      />
                    </Box>
                  </Hidden>
                </>
              }

            </Grid>
          </Grid>
        }
        {includeEventHeading && isMobile &&
          <Grid container spacing={0}>
            <Grid item xs={12} className={contentType === EVENT_TYPE && eventListingTemplateId === EVENT_LISTING_TEMPLATE_ID && classes.eventListingLogoWrapper}>
              <Typography wordBreak title={title.value} className={classes.headingTitle} variant="h2" component="h1" gutterBottom={false}>
                {title && <Text field={title} />}
              </Typography>
            </Grid>
          </Grid>
        }
      </Grid>
    </div>
  );
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(Breadcrumbs))
);