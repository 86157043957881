import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { pxToRem } from '../../../../styles/themes/vad/constants';

export default makeStyles(theme => ({
  '@global': {
    '.breadcrumbWrapper': {
      '&.hide': {
        display: 'none'
      },
      '& + .mediaWrapper': {
        '& + .listingPageWrapper': {
          paddingTop: pxToRem(51),
          marginTop: 0,
        }
      },
      '& + .listingPageWrapper': {
        paddingTop: pxToRem(51),
        marginTop: 0,
      }
    },
    '& .listingPageWrapper': {
      '&.adcalenderbanner': {
        paddingTop: pxToRem(51),
      }
    }
  },
  root: {
    '&.ADDetailPageMargin': {
      paddingTop: pxToRem(24),
    },
    paddingTop: pxToRem(44),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginBottom: pxToRem(44),
    },
  },
  pageHeadingWrapper: {

    backgroundColor: (params) => get(params, 'backgroundColor', ''),
    backgroundImage: (params) => `url(/-/media/${get(params, 'bgImage', '')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',

    paddingTop: pxToRem(44),
    paddingBottom: pxToRem(16),
    ...theme.mixins.paddingLeft(pxToRem(24)),
    ...theme.mixins.paddingRight(pxToRem(24)),

    [theme.breakpoints.up('lg')]: {
      paddingTop: pxToRem(66),
      paddingBottom: pxToRem(37),
      ...theme.mixins.paddingLeft(pxToRem(88)),
      ...theme.mixins.paddingRight(pxToRem(90.3))
    }
  },
  eventListingLogoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& img': {
      top: pxToRem(-16),
      position: 'relative',
      objectFit: 'contain',
    },
  },
  headingTitle: {
    color: (params) => get(params, 'textColor', ''),
    '& h1': {
      ...theme.palette.common.ellipsis(2),
    }
  },
  imageWrapper: {
    position: 'relative',
    height: '73px',
    width: '200px',
    [theme.breakpoints.up('xl')]: {
      width: '262px',
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
      display: 'none'
    },
  },
  breadcrumbs: {
    '& .AdDetailPageCrumb': {
      textWrap: 'nowrap',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    // [theme.breakpoints.down('md')]: {
    //   '& li:nth-last-child(2)': {
    //     display: 'none ',
    //   },
    // },
    '&.AdDetailNoWrap': {
      marginBottom: pxToRem(8),
      '& ol': {
        flexWrap: 'nowrap',
      }
    },
    '&.textStyle': {

      '& ol': {
        '& li': {
          '&.MuiBreadcrumbs-separator': {
            margin: `0 ${pxToRem(8)}`
          },
          '& a , .MuiTypography-root': {
            '& .MuiBreadcrumbs-separator': {
              '& .MuiTypography-root': {
                [theme.breakpoints.down('md')]: {
                  display: 'flex',
                },
              }
            },

          }
        }
      }
    },
    '& ol': {
      margin: 0,
      display: 'flex',
      padding: 0,
      flexWrap: 'wrap',
      listStyle: 'none',
      lineHeight: '16px',
      alignItems: 'center',
      fontSize: '14px',
      '& li': {
        lineHeight: '16px',
        fontSize: '14px',
        '&.MuiBreadcrumbs-separator': {
          lineHeight: '16px',
          fontSize: '14px',

          [theme.breakpoints.down('md')]: {
            transform: `rotate(${theme.isDirectionRTL ? '180deg' : '0deg'})`,
          },
        },
        '& .MuiTypography-root': {
          display: 'block',
          lineHeight: '16px',
          fontSize: '14px',
          [theme.breakpoints.down('md')]: {
            lineHeight: '24px',
          },
        }
      }
    },
    '& .MuiBreadcrumbs-separator': {
      display: 'initial',
      marginLeft: '8px',
      marginRight: '8px',
      userSelect: 'none',
      [theme.breakpoints.down('md')]: {
        ...theme.mixins.marginLeft(8),
        ...theme.mixins.marginRight(8),
        // marginLeft: '10px',
        // marginRight: '2.5px',
      },
      color: (params) => get(params, 'textColor', ''),
    },
  },
  breadcrumbLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      color: theme.mixins.AbuDhabiOrange(),
    },
  },
  lastBreadcrumb: {
    // display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'inline',
      color: (params) => get(params, 'textColor', theme.palette.text.tertiary),
    },
    '&.detailPageLastBreadCrumb': {
      color: '#000000',
      [theme.breakpoints.down('sm')]: {
        display: '-webkit-box !important',
        '-webkitLineClamp': 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkitBoxOrient': 'vertical'
      },
    }
  },
}));
